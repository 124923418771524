import {
    SampledProperty,
    SampledPositionProperty, Rotation,
    defaultValue, ClockRange, ClockStep, sampleTerrainMostDetailed, ClockViewModel,
    JulianDate, IonResource, Math as CesiumMath, Transforms,
    HeadingPitchRoll, Color,
    Cartographic, Cartesian3,
    HeightReference,
    VerticalOrigin,
    Viewer,
    createWorldTerrainAsync,
    VelocityOrientationProperty,
    HeadingPitchRange

} from "cesium";
import Papa from "papaparse";
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty'
import pfdLayerElements from "../../pages/dashboard/components/PfdScreen/pfdLayerElements";
import { PhaseColor } from "../../assets/styles/phaseColors";
import { calculateHeading } from "./utils";
import * as Cesium from 'cesium';


// Customized angle for correcting interpretation between two angles
let Angle = {
    // Length of the packed array representation
    packedLength: 1,

    // Length of the packed array representation used for interpolation
    packedInterpolationLength: 1,

    /**
     * Packs the angle value into the specified array at the given starting index.
     * @param {number} value - The angle value to be packed.
     * @param {Array} array - The array to store the packed value.
     * @param {number} startingIndex - The starting index in the array.
     * @returns {void}
     */
    pack: function (value, array, startingIndex) {
        startingIndex = defaultValue(startingIndex, 0);
        array[startingIndex] = value;
    },

    /**
     * Unpacks the angle value from the specified array at the given starting index and returns it.
     * @param {Array} array - The array containing the packed angle value.
     * @param {number} startingIndex - The starting index in the array.
     * @returns {number} - The unpacked angle value.
     */
    unpack: function (array, startingIndex, result) {
        startingIndex = defaultValue(startingIndex, 0);
        return array[startingIndex];
    },

    /**
     * Converts the packed array representation for interpolation.
     * @param {Array} packedArray - The packed array to be converted.
     * @param {number} startingIndex - The starting index in the packed array.
     * @param {number} lastIndex - The last index in the packed array.
     * @param {Array} result - The array to store the converted values.
     * @returns {void}
     */
    convertPackedArrayForInterpolation: function (packedArray, startingIndex, lastIndex, result) {
        for (let i = 0, len = lastIndex - startingIndex + 1; i < len; i++) {
            result[i] = packedArray[startingIndex + i];
        }
    },

    /**
     * Unpacks the interpolated result value and adjusts it based on angular considerations.
     * @param {number} value - The interpolated result value.
     * @param {Array} sourceArray - The array containing the source values for interpolation.
     * @param {number} firstIndex - The index of the first source value.
     * @param {number} lastIndex - The index of the last source value.
     * @param {Array} result - The array to store the final unpacked values.
     * @returns {number} - The adjusted unpacked angle value.
     */
    unpackInterpolationResult: function (value, sourceArray, firstIndex, lastIndex, result) {
        let start = CesiumMath.toDegrees(sourceArray[firstIndex]);
        let end = CesiumMath.toDegrees(sourceArray[lastIndex]);
        let difference = Math.abs(end - start);

        if (difference >= 350) {
            let midDeg1;
            if (start >= 350) {
                midDeg1 = (start + 360) / 2;
                value = CesiumMath.toRadians(midDeg1);
            } else if (end >= 350) {
                midDeg1 = (end + 360) / 2;
                value = CesiumMath.toRadians(midDeg1);
            } else {
                console.log("Unhandled cases");
                value = sourceArray[lastIndex];
            }
            return value;
        }
        return value;
    }
};

export const clockViewModel = new ClockViewModel();

//Variable to be added 
//Cesium enables us to add value of a variable at a given time stamp using sampledproperty
export const positionsOverTime = new SampledPositionProperty();
export const propertyRoll = new SampledProperty(Rotation);
export const propertyPitch = new SampledProperty(Rotation);
export const propertyHead = new SampledProperty(Angle);//Angle is custom defined above
export const propertyIAS = new SampledProperty(Number);
export const propertyVSPD = new SampledProperty(Number);
export const propertyALT = new SampledProperty(Number);
export const propertyTAS = new SampledProperty(Number);
export const positionsOverTime2d = new SampledPositionProperty();
export const propertyWndSpd = new SampledProperty(Number)
export const propertyWndDr = new SampledProperty(Rotation)
export const propertyNAV1 = new SampledProperty(Number)
export const propertyNAV2 = new SampledProperty(Number)
export const propertyCOM1 = new SampledProperty(Number)
export const propertyCOM2 = new SampledProperty(Number)
export const propertyCRS = new SampledProperty(Rotation)
export const propertyBaroA = new SampledProperty(Number)
export const propertyOAT = new SampledProperty(Number)
export const propertyNormAc = new SampledProperty(Number)
export const propertyLatAc = new SampledProperty(Number)
export const propertyLatitude = new SampledProperty(Number)
export const propertyLongitude = new SampledProperty(Number)
export const propertyE1RPM = new SampledProperty(Number)
export let currentTimeData = { altitude: 0, heading: 0, iASpeed: 0 }

export let csvJSonArray = [];
const FEET_TO_METER = 0.3048;
const METER_TO_NM = 1 / 1852

// Array of keys
// This specifies what are the columns we are reading from csv file and storing in csvJsonArray
// One element of csvJsonArray will have value of the all keys defined in indexArr. 
const indexArr = ["AltGPS", "HDG", "Lcl Time", "Lcl Date", "Roll", "IAS", "VSpd", "Longitude", "Latitude", "Pitch", "AltMSL", "TAS", "WndSpd", "WndDr", "NAV1", "NAV2", "COM1", "COM2", "CRS", "BaroA", "OAT", "NormAc", "LatAc", "E1 RPM", "GndSpd", "UTCOfst"];

const funcHolder = {};
const entityHolder = {};
let isAirplaneEntityLoaded = false;

/**
 * Reads a CSV file, processes its data, and updates the Cesium viewer.
 * @param {File} file - The CSV file to be read.
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Function} plotWallCallBack - Callback function to add flight path, used as callback because sycronzation
 * @param {Object} analysisResponse - response got after calling debrief info api - sent as a parameter in plotwallCallBack function
 * @returns {Promise<void>} - A promise that resolves after the file is processed.
 */
export async function ReadFile(file, viewer, plotWallCallBack, analysisResponse) {
    csvJSonArray = file;    //this is to update the csvJSonArray with new data as it had old data after debrief player ran at least once
    
    const lastElement = csvJSonArray[csvJSonArray.length-1];
    const secondLastElement = csvJSonArray[csvJSonArray.length-2];
    
    // to handle cases where last row data is not complete
    if (Object.keys(lastElement).length !== Object.keys(secondLastElement).length) {
        csvJSonArray[csvJSonArray.length-1] = csvJSonArray[csvJSonArray.length-2];
    }
    
    modifyJsonArray(file);
    let pointArr = [];

    for (let index = 0; index < csvJSonArray.length; index++) {
        //Handling missing heading values 
        if (!csvJSonArray[index]?.HDG || csvJSonArray[index]?.HDG.toString().trim() === "") {
            if (csvJSonArray[index - 1]?.HDG && csvJSonArray[index - 1]?.HDG.toString().trim() !== "" && csvJSonArray[index + 1]?.HDG && csvJSonArray[index + 1]?.HDG.toString().trim() !== "") {
                csvJSonArray[index].HDG = csvJSonArray[index - 1]?.HDG
            }
            else {
                //  console.log("Indx "+index)
                let heading = calculateHeading(+csvJSonArray[index]?.Latitude, +csvJSonArray[index]?.Longitude, +csvJSonArray[index + 1]?.Latitude, +csvJSonArray[index + 1]?.Longitude)
                csvJSonArray[index].HDG = heading || 0;
            }
        }
        // Creating an array of Cartographic points from the CSV JSON data
        pointArr.push(Cartographic.fromDegrees(
            +csvJSonArray[index].Longitude,
            +csvJSonArray[index].Latitude,
        ));
    }

    // Enabling depth test against terrain in the Cesium globe
    viewer.scene.globe.depthTestAgainstTerrain = true;

    let calibarHeight = 0;

    // Sampling terrain height for each Cartographic point
    sampleTerrainMostDetailed(viewer.terrainProvider, pointArr)
        .then(function (updatedPositions) {
            // Processing height data and updating CSV JSON array
            for (let index = 0; index < updatedPositions.length; index++) {
                let height = (csvJSonArray[index].AltMSL * FEET_TO_METER);
                if (index === 0) calibarHeight = height - updatedPositions[index].height;
                csvJSonArray[index].AltGPS = height - calibarHeight;
                let diifHeight = csvJSonArray[index].AltGPS - updatedPositions[index].height;
                if (diifHeight < 0) csvJSonArray[index].AltGPS = updatedPositions[index].height + 7;
            }

            // Adding variables to the sample property
            AddVariablesToSampleProperty(csvJSonArray);

            // Setting the clock based on CSV JSON data
            SetClock(csvJSonArray, viewer);

            // Ploting wall after updatingb height for each location
            plotWallCallBack(analysisResponse);
        });
}



/**
 * Gets the index number of each specified column and saves it in a JSON object as {columnName: indexNumber}.
 * @param {Array} csvData - The CSV data array.
 * @returns {Object} - JSON object containing column names and their respective index numbers.
 */
function GetIndexJson(csvData) {
    // Initialize an empty object to store column names and index numbers
    let indexJson = {};

    // Loop through the specified column names in the 'indexArr' array
    indexArr.map(e => {
        // Call the 'GetIndex' function to get the index number of each column in the CSV data header (assuming it's in the third row)
        indexJson[e] = GetIndex(e, csvData[2]);
    });

    // Return the JSON object with column names and their corresponding index numbers
    return indexJson;
}


/**
 * Gets the index number of a specific column in the header row.
 * @param {string} column - The column name.
 * @param {Array} headerRow - The header row array.
 * @returns {number} - The index of the column.
 */
function GetIndex(column, headerRow) {
    // Iterate through the header row to find the index of the specified column
    for (let index = 0; index < headerRow.length; index++) {
        let key = headerRow[index].toString().trim();
        if (column === key) {
            return index;
        }
    }
}

/**
 * Converts CSV data to JSON format, handling missing or undefined values.
 * @param {Array} csvData - The CSV data array.
 * @returns {Array} - An array of JSON objects representing the converted data.
 */
function ConvertToJSON(csvData) {
    // Get the index JSON object for each column
    let indexJson = GetIndexJson(csvData);

    // Get the index of the last defined column
    let indexAllDefined = GetAllDefinedIndex(csvData, indexJson);

    // Extract header row after all defined columns
    let aRowNew = csvData[indexAllDefined];

    // Initialize the resulting JSON array
    csvJSonArray = [];

    // Iterate through the CSV data starting from the 4th row (assuming the first three rows are headers and index information)
    for (let i = 3, j = 1; i <= csvData.length - 1; i++, ++j) {
        // Extract the current row of data
        let aRow = csvData[i];

        // Initialize a new JSON object for the current row
        let dataJson = {};

        // Iterate through columns in the index JSON
        for (const [key, value] of Object.entries(indexJson)) {
            // Extract the value from the current row based on the index
            let val = aRow[value];

            // Handle special cases for "Longitude" and "Latitude"
            if ("Longitude" === key || "Latitude" === key) {
                if (!val || val.toString().trim() == "" || val == 0) {
                    // Handle missing or undefined values for "Longitude" and "Latitude"
                    if (i < indexAllDefined - 3) {
                        val = aRowNew[value];
                    } else {
                        val = csvJSonArray[j - 2][key];
                    }
                }
            } else if(key!=="HDG"){
                // Handle missing or undefined values for other columns
                if (!val || val.toString().trim() == "") {
                    if (i < indexAllDefined - 3) {
                        val = aRowNew[value];
                    } else {
                        val = csvJSonArray[j - 2][key];
                    }
                }
            }
            // Assign the value to the corresponding key in the JSON object
            dataJson[key] = val;
        }
        // Push the JSON object to the resulting array
        csvJSonArray.push(dataJson);
    }
    // Return the resulting JSON array
    return csvJSonArray;
}


const modifyJsonArray = (jsonData) => {
    let keys = ["AltMSL", "Latitude", "Longitude", "IAS", "AltGPS"];
    let definedObject = definedObj(jsonData)

    try {
        jsonData.map((obj, index) => {
            for (const key in obj) {
                if (keys.includes(key)) {
                    if (obj[key] === "" || obj[key] === undefined || obj[key] === null) {
                        obj[key] = definedObject[key]
                    } else {
                        definedObject[key] = obj[key]
                    }
                }
            }
        })
    } catch (error) {
        console.log(error)
    }

    csvJSonArray = []
    csvJSonArray = jsonData
}


const definedObj = (jsonData) => {
    let keys = ["AltMSL", "Latitude", "Longitude", "IAS", "AltGPS", "Roll", "Pitch"];
    let gotDefinedObj = false
    for(let arrObj = 0; arrObj < jsonData.length; arrObj++){
        gotDefinedObj = true
        for (const key in jsonData[arrObj]) {

            if(keys.includes(key)){
                const value = jsonData[arrObj][key];
                if(value === undefined || value === null || value ==="" ){
                    gotDefinedObj = false
                }
            }  
        }
        if(gotDefinedObj){
            return jsonData[arrObj]
        }
    }
}



/**
 * Gets the index of the last row where all defined columns have non-empty values.
 * @param {Array} csvData - The CSV data array.
 * @param {Object} indexJson - JSON object containing column names and their respective index numbers.
 * @returns {number} - The index of the last row with non-empty values in all defined columns.
 */
function GetAllDefinedIndex(csvData, indexJson) {
    // Iterate through the CSV data starting from the 4th row (assuming the first three rows are headers and index information)
    for (let i = 3; i <= csvData.length - 1; i++) {
        // Extract the current row of data
        let aRow = csvData[i];

        // Initialize a counter for non-empty values
        let count = 0;

        // Iterate through columns in the index JSON
        for (const [key, value] of Object.entries(indexJson)) {
            // Extract the value from the current row based on the index
            let val = aRow[value];

            // Check if the value is non-empty
            if (val && !val.toString().trim() == "") {
                count++;
            }
        }
        // If the count matches the number of defined columns, return the current index
        if (indexArr.length === count) {
            return i;
        }
    }
}

/**
 * Adds variables to the sample property based on the provided CSV JSON array.
 * @param {Array} csvJSonArray - The CSV JSON array containing data.
 * @returns {void}
 */
export function AddVariablesToSampleProperty(csvJSonArray) {
    // Iterate through the CSV JSON array
    // for (let index = 0; index < csvJSonArray.length-1; index++) {
    for (let index = 0; index < csvJSonArray.length; index++) {
        const element = csvJSonArray[index];

        // Get Julian Date from the current element
        //const time = GetJulianDate(element);
        const time = GetJulianDateFromTimeStamp(element)

        // Check if essential properties are available in the element
        if (element && element.AltGPS && element.Latitude && element.Longitude) {
            // Convert Latitude, Longitude, and Altitude to Cartesian3 position
            const pos = Cartesian3.fromDegrees(+element.Longitude, +element.Latitude, +element.AltGPS);

            // Add samples to the respective properties over time
            positionsOverTime.addSample(time, pos);
            propertyRoll.addSample(time, CesiumMath.toRadians(+element.Roll));
            propertyHead.addSample(time, CesiumMath.toRadians(+element.HDG));
            propertyPitch.addSample(time, CesiumMath.toRadians(+element.Pitch));
            propertyWndDr.addSample(time, CesiumMath.toRadians(+element.WndDr));
            propertyIAS.addSample(time, +element.IAS);
            propertyALT.addSample(time, +element.AltMSL);
            propertyVSPD.addSample(time, +element.VSpd);
            propertyTAS.addSample(time, +element.TAS);
            propertyWndSpd.addSample(time, +element.WndSpd);
            propertyNAV1.addSample(time, +element.NAV1);
            propertyNAV2.addSample(time, +element.NAV2);
            propertyCOM1.addSample(time, +element.COM1);
            propertyCOM2.addSample(time, +element.COM2);
            propertyCRS.addSample(time, CesiumMath.toRadians(+element.CRS));
            propertyBaroA.addSample(time, +element.BaroA);
            propertyOAT.addSample(time, +element.OAT);
            propertyNormAc.addSample(time, +element.NormAc);
            propertyLatAc.addSample(time, +element.LatAc);
            propertyLatitude.addSample(time, +element.Latitude);
            propertyLongitude.addSample(time, +element.Longitude);
            propertyE1RPM.addSample(time, +element["E1 RPM"]);

            // Add samples to the 2D positions over time
            positionsOverTime2d.addSample(time, pos);
        }
    }
}

/**
 * Sets up the Cesium clock based on the provided CSV JSON array and viewer.
 * @param {Array} csvJSonArray - The CSV JSON array containing data.
 * @param {Viewer} viewer - The Cesium viewer.
 * @returns {void}
 */
export function SetClock(csvJSonArray, viewer, clockState = false) {
    // Add an onTick event listener to the viewer clock
    AddOnTickEventListener(viewer);

    // Choose an element for the start time (fallback to indices 0, 20, 25, or 35)
    const element = csvJSonArray[0] || csvJSonArray[20] || csvJSonArray[25] || csvJSonArray[35];

    // Get the Julian Date for the start time
    //const startTime = GetJulianDate(element);
    const startTime = GetJulianDateFromTimeStamp(element)

    // Choose the last element for the end time (fallback to last 1, 10, or 20 elements)
    const lastElement = csvJSonArray[csvJSonArray.length - 1] || csvJSonArray[csvJSonArray.length - 10] || csvJSonArray[csvJSonArray.length - 20];
    

    // Get the Julian Date for the end time
    //const endTime = GetJulianDate(lastElement);
    const endTime = GetJulianDateFromTimeStamp(lastElement)

    // Set up the viewer's clock properties
    viewer.clock.startTime = startTime.clone();
    viewer.clock.stopTime = endTime.clone();
    viewer.clock.currentTime = startTime.clone();
    viewer.timeline.zoomTo(startTime, endTime);
    viewer.clock.multiplier = 1;
    // viewer.clock.shouldAnimate = true;
    viewer.clock.shouldAnimate = clockState;
    viewer.clock.clockRange = ClockRange.LOOP_STOP; // loop when we hit the end time
    viewer.clock.clockStep = ClockStep.SYSTEM_CLOCK_MULTIPLIER;
    // viewer.clock.canAnimate = true;
    viewer.clock.canAnimate = clockState;
}


/**
 * Adds an onTick event listener to the Cesium viewer's clock for updating camera view and layer elements.
 * @param {Viewer} viewer - The Cesium viewer.
 * @returns {void}
 */
function AddOnTickEventListener(viewer) {
    // console.log(`viewer: `, viewer);
    // Unsubscribe from the previous event if it exists
    if (funcHolder.unsubScribeEvent1) funcHolder.unsubScribeEvent1();

    // Add a new onTick event listener to the viewer's clock
    let event = viewer.clock.onTick.addEventListener(function (clock) {
        try {
            // Get current time from the clock
            let CurrTime = clock.currentTime;

            // Get current position, heading, roll, and pitch from properties over time
            let currPosition = positionsOverTime.getValue(CurrTime);
            let currHead = propertyHead.getValue(CurrTime);
            let currRoll = propertyRoll.getValue(CurrTime);
            let currPitch = propertyPitch.getValue(CurrTime);

            // Convert heading to degrees and handle values greater than 359
            let headDeg = CesiumMath.toDegrees(currHead);
            if (headDeg > 359) headDeg = 0;

            // Set the corresponding position and orientation at a given time stamp to a camera view
            viewer.camera.setView({
                destination: currPosition,
                orientation: {
                    heading: currHead,
                    pitch: currPitch,
                    roll: currRoll
                },
            });

            // Call the function to update layer elements with various properties at the current time
            pfdLayerElements(
                propertyIAS.getValue(CurrTime),
                propertyTAS.getValue(CurrTime),
                propertyALT.getValue(CurrTime),
                propertyVSPD.getValue(CurrTime),
                propertyBaroA.getValue(CurrTime),
                propertyHead.getValue(CurrTime),
                propertyWndSpd.getValue(CurrTime),
                propertyWndDr.getValue(CurrTime),
                propertyCRS.getValue(CurrTime),
                propertyRoll.getValue(CurrTime),
                propertyPitch.getValue(CurrTime),
                propertyLatAc.getValue(CurrTime),
                propertyOAT.getValue(CurrTime),
                Math.round(propertyE1RPM.getValue(CurrTime)),
                propertyNormAc.getValue(CurrTime),
                propertyNAV1.getValue(CurrTime),
                propertyNAV2.getValue(CurrTime),
                propertyCOM1.getValue(CurrTime),
                propertyCOM2.getValue(CurrTime)
            );
        } catch (error) {
            // console.log(`removed`);
            let viewerElement = document.getElementById('viewer3d');
            viewer.clock.onTick.removeEventListener(event);
            viewerElement.remove();
            console.log(error);
        }
    });

    // Store the event in the function holder for later unsubscription
    // funcHolder["unsubScribeEvent1"] = event;
    funcHolder["unsubScribeEvent1"] = () => viewer.clock.onTick.removeEventListener(event);
}


/**
 * Converts date and time information from a CSV element to a Julian Date.
 * @param {Object} element - The CSV element containing date and time information.
 * @returns {JulianDate} - The Julian Date corresponding to the provided date and time.
 */
export function GetJulianDate(element) {
    try {
        // Split time and date information from the element
        let timeArr = element["Lcl Time"].trim().split(":");
        let dateArr = element["Lcl Date"].trim().split("-");
        let utcOffset = element["UTCOfst"].trim();

        // Create a formatted date string
        let date = dateArr[0] + "-" + dateArr[1] + "-" + dateArr[2] + "T" + timeArr[0] + ":" + timeArr[1] + ":" + timeArr[2] + utcOffset;

        // Convert the formatted date string to a Julian Date
        const time = JulianDate.fromDate(new Date(date));
        return time;
    } catch (error) {
        // Handle alternative date format with "/" separator
        let timeArr = element["Lcl Time"].trim().split(":");
        let dateArr = element["Lcl Date"].trim().split("/");
        try {
            let utcOffset = element["UTCOfst"].trim();
            // Create a formatted date string for the alternative format
            let date = dateArr[2] + "-0" + dateArr[0] + "-" + dateArr[1] + "T" + timeArr[0] + ":" + timeArr[1] + ":" + timeArr[2] + utcOffset;
            // Update the "Lcl Date" property with the formatted date
            element["Lcl Date"] = dateArr[2] + "-0" + dateArr[0] + "-" + dateArr[1];
            // Convert the formatted date string to a Julian Date
            const time = JulianDate.fromDate(new Date(date));
            return time;
        } catch (err) {
            console.log("Check date and time columns")
            console.log(error);
            // Return a default Julian Date in case of errors
            return JulianDate.fromDate(new Date(2023, 6, 24, 0, 0, 0));
        }
    }
}

export const GetJulianDateFromTimeStamp = (element) => {
    // console.log(`element: `, element)
    let timeStamp = element["DateTimeUtc"];
    // if (timeStamp === `3/15/2024 11:58:44 AM` || timeStamp === `3/15/2024 12:00:46 PM`) {
    //     console.log(`timeStamp string: `, timeStamp)
    // }
    // console.log(`Date before `);
    // console.log(timeStamp)
    let dateTime = parseDateString(timeStamp);
    // if (timeStamp === `3/15/2024 11:58:44 AM` || timeStamp === `3/15/2024 12:00:46 PM`) {
    //     console.log(dateTime)
    // }
    //    console.log(`Date before `,timeStamp)
    //     let date = timeStamp.split(" ")[0].split("/")
    //     //let dayTime = timeStamp.split(" ")[1]
    //     let timeArr = timeStamp.split(" ")[1].split(":");
    //     console.log(`time arr `,timeArr)
    //     let dateTime = date[2] +"-0"+ date[0] +"-"+ date[1] +"T0" + timeStamp.split(" ")[1] + "+00:00";
    //     console.log(`Date ss`, dateTime)
    // Convert the formatted date string to a Julian Date
    const time = JulianDate.fromDate(new Date(dateTime));
    // console.log(`Date `, dateTime)

    return time;

}

function parseDateString(dateString) {
    const parts = dateString.split(' ');

    const dateParts = parts[0].split('/');
    const timeParts = parts[1].split(':');

    const month = parseInt(dateParts[0]) - 1; // Months are 0-indexed in JavaScript
    const day = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    let hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);

    // Adjust hours for AM/PM
    if ( (parseInt(parts[1].split(`:`)[0]) >= 13)  && parts[2] === 'PM') {
        hours += 12;
    }

    const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

    return date;
}

/**
 * Adds an onTick event listener to the Cesium viewer's clock for updating a 2D airplane entity's position and orientation.
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Entity} airplaneEntity - The Cesium entity representing the airplane.
 * @returns {void}
 */
function AddOnTickEventListenerForTwoD(viewer, airplaneEntity) {
    // Enable depth testing against terrain in the scene
    viewer.scene.globe.depthTestAgainstTerrain = true;

    // Unsubscribe from the previous event if it exists
    if (funcHolder.unsubScribeEvent2) funcHolder.unsubScribeEvent2();

    // Add a new onTick event listener to the viewer's clock
    let event = viewer.clock.onTick.addEventListener(function (clock) {
        try {
            // Get current time from the clock
            let currTime = clock.currentTime;

            // Get current position from properties over time
            let posVal = positionsOverTime.getValue(currTime);

            // Get current heading, roll, and pitch from properties over time
            let currHead = propertyHead.getValue(currTime);
            let currRoll = propertyRoll.getValue(currTime);
            let currPitch = propertyPitch.getValue(currTime);

            // Calculate adjusted heading for proper orientation
            let headChange = CesiumMath.toDegrees(currHead) - 90;

            // Store current time data for display
            currentTimeData = {
                altitude: Math.round(propertyALT.getValue(currTime)),
                heading: Math.round(currHead * 180 / Math.PI),
                iASpeed: Math.round(propertyIAS.getValue(currTime))
            };

            // Control the position and orientation of the airplane entity for each frame
            if (posVal && airplaneEntity) {
                airplaneEntity.position = posVal;

                // Create a new HeadingPitchRoll for orientation
                let currHeadRoll = new HeadingPitchRoll(CesiumMath.toRadians(headChange), currPitch, currRoll);

                // Update the orientation of the airplane entity using quaternion
                airplaneEntity.orientation = Transforms.headingPitchRollQuaternion(posVal, currHeadRoll);
            }
        } catch (error) {
            console.log("Error adding onTick Event Listener");
            console.log(error);
        }
    });

    // Store the event in the function holder for later unsubscription
    funcHolder["unsubScribeEvent2"] = event;
}


const mainPhases = [`Taxi`, `Takeoff`, `Climb`, `Cruise`, `Descent`, `Approach`, `Landing`];


/**
 * Adds a wall and a polyline as Cesium entities and returns both in an array.
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Array} array - The array containing latitude, longitude, altitude values.
 * @param {string} label -phase/subPhase Name
 * @returns {Array} - An array containing the added wall and polyline entities.
 */
//Added wall and a polyline as entities and return both in an array,
//label=Phase, array=lat,long,alt,lat etc
export function PlotWallNPolyline(viewer, array, label) {
    
    // console.log(`------------`);
    // console.log(`viewer: `, viewer);
    // console.log(`array: `, array);
    // console.log(`label: `, label);
    // console.log(`------------`);

    array = array.map((item, index) => index % 3 === 2 ? (item + 5.5) : item);

    let retArray = [];
    let CesiumClr; // Color for the wall
    let ployClr; // Color for the polyline
    
    // if (mainPhases.includes(label)) {

        // Check if PhaseColor[label] is defined, use the specified color; otherwise, use default colors
        if (PhaseColor[label]) {
            CesiumClr = PhaseColor[label][0] || Color.fromCssColorString("rgba(10, 10, 245, 0.4)");
            ployClr = PhaseColor[label][1] || Color.fromCssColorString("rgba(10, 10, 245)");
        } else {
            // CesiumClr = Color.fromCssColorString("rgb(165,104,42, 0.4)");   //brown
            // ployClr = Color.fromCssColorString("rgb(165,104,42)");  //brown
            CesiumClr = Color.fromCssColorString("rgb(211,211,211, 0.4)");   //light Grey
            ployClr = Color.fromCssColorString("rgb(211,211,211)");  //light Grey
        }

        // Add a wall entity to the viewer
        let wall = viewer.entities.add({
            name: "wall",
            wall: {
                positions: Cartesian3.fromDegreesArrayHeights(array),
                width: 3,
                material: CesiumClr,
                eyeOffset: new Cartesian3(0, 0, 2000),
                followSurface: true,
                heightReference: HeightReference.CLAMP_TO_GROUND,
            },
        });

        // Add a polyline entity to the viewer
        let polyline = viewer.entities.add({
            polyline: {
                positions: Cartesian3.fromDegreesArrayHeights(array),
                width: 5.0,
                material: ployClr,
                eyeOffset: new Cartesian3(0, 0, 200),
            }
        });

        // Push both entities to the return array
        retArray.push(wall);
        retArray.push(polyline);

    // }
    return retArray;
}



/**
 * Adds or retrieves an airplane model entity to/from the Cesium viewer.
 * @param {Viewer} viewer - The Cesium viewer.
 * @returns {Entity|Error} - The airplane model entity or an error if loading fails.
 */
export async function addTrackEntity(viewer) {
    if (!isAirplaneEntityLoaded) {
        isAirplaneEntityLoaded = true;
        try {
            // Load the airplane model URI asynchronously
            // let airplaneUri = await IonResource.fromAssetId(1639891);
            let airplaneUri = await IonResource.fromAssetId(2388366);
            // let airplaneUri = "/assets/models/Cessna.gltf";
            entityHolder["airplaneURI"] = airplaneUri;

            // Retrieve or create the airplane entity with the loaded URI
            let airplaneEntity = getAirPlaneModel(viewer, entityHolder.airplaneURI);
            
            return airplaneEntity;
        } catch (error) {
            return error;
        }
    } else {
        // Retrieve the airplane entity if already loaded
        let airplaneEntity = getAirPlaneModel(viewer, entityHolder.airplaneURI);
        
        return airplaneEntity;
    }
}


/**
 * Controls the camera view in the Cesium viewer by adjusting heading, pitch, and range.
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {number} head - The additional heading adjustment.
 * @param {number} pitch - The pitch adjustment.
 * @param {number} range - The range adjustment.
 * @param {function} setcallBackFn - The callback function and store the event so that when required onTick event is unsubscribed
 * @returns {void}
 */
export const controllCamView = (viewer, head, pitch, range, setcallBackFn) => {
    // console.log(`controllCamView Called`)
    let camera = viewer?.camera;

    // Add an onTick event listener to adjust the camera view based on time
    let event = viewer?.clock.onTick.addEventListener(function (clock) {
        try {
            // Get current time from the clock
            let CurrTime = clock.currentTime;

            // Get current position from properties over time
            let pos = positionsOverTime.getValue(CurrTime);

            // Get current heading from properties over time
            let currHead = propertyHead.getValue(CurrTime);
            let headDeg = CesiumMath.toDegrees(currHead) + head;

            // Adjust heading to handle values greater than 359
            if (headDeg > 359) headDeg -= 360;

            // Look at the specified position with adjusted heading, pitch, and range
            camera.lookAt(pos, new HeadingPitchRange(CesiumMath.toRadians(headDeg), CesiumMath.toRadians(pitch), range));
        } catch (err) {
            console.log(err);
        }
    });

    // Store the event in the callback function for later unsubscription
    let varBel = { callback: event };
    setcallBackFn(varBel);
};


/**
 * Creates and adds an airplane model entity to the Cesium viewer.
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Resource} uri - The URI of the airplane model.
 * @returns {Entity} - The created airplane model entity.
 */
// function getAirPlaneModel(viewer, uri) {
//     // console.log(`uri: `, uri);
//     // Add an airplane entity with specified properties
//     let airplaneEntity = viewer.entities.add({
//         position: positionsOverTime,
        // model: {
        //     uri: uri,
        //     color: Color.YELLOW,
        //     // color: Color.WHITE,
        //     // scale: 1.0,
        //     scale: 0.11,
        //     minimumPixelSize: 80,
        //     maximumScale: 20,
        // },
//         label: {
//             showBackground: true,
//             text: 'test text',
//             font: '10px sans-serif',
//             horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
//             verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
//             // pixelOffset: new Cesium.Cartesian2(150, -150),
//             pixelOffset: new Cesium.Cartesian2(50, -50),
//             // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e7, 0.5),
//             // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(1.5e3, 1.0, 1.0e6, 0.7),
//             // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(1.5e3, 1.0, 1.0e6, 0.005),
//             pixelOffsetScaleByDistance: new Cesium.NearFarScalar(1.5e3, 0.35, 1.0e6, 0.005),
//         },
//         followSurface: true,
//         verticalOrigin: VerticalOrigin.BOTTOM,
//         eyeOffset: new Cartesian3(0.0, 2000, 0.0),
//         heightReference: HeightReference.RELATIVE_TO_GROUND
//     });

//     // Set the tracked entity to the airplane entity
//     viewer.trackedEntity = airplaneEntity;

//     // Add onTick event listener to update the label dynamically
//     viewer.clock.onTick.addEventListener(() => {
//         // Update the label text with dynamic data, e.g., position
//         const position = Cesium.Cartesian3.clone(airplaneEntity.position.getValue(viewer.clock.currentTime));
//         let currTime = viewer.clock.currentTime;
//         console.log(viewer.clock)
//         if (position) {
//             airplaneEntity.label.text = `${Math.round(propertyALT.getValue(currTime))}' MSL\n
//             ${Math.round(propertyHead.getValue(currTime) * 180 / Math.PI)}°\n
//             ${Math.round(propertyIAS.getValue(currTime))}K`;
//         }
//     });

//     // Add onTick event listener for updating position and orientation of the airplane entity
//     AddOnTickEventListenerForTwoD(viewer, airplaneEntity);

//     return airplaneEntity;
// }


function getAirPlaneModel(viewer, uri) {

    // console.log(`viewer: `, viewer);
    // console.log(`uri: `, uri);

    // Add an airplane entity with specified properties
    let airplaneEntity = viewer.entities.add({
        position: positionsOverTime,
        model: {
            uri: uri,
            color: Color.YELLOW,
            scale: 0.11,
            minimumPixelSize: 80,
            maximumScale: 20,
        },
        followSurface: true,
        verticalOrigin: VerticalOrigin.BOTTOM,
        eyeOffset: new Cartesian3(0.0, 2000, 0.0),
        heightReference: HeightReference.RELATIVE_TO_GROUND
    });

    // Set the tracked entity to the airplane entity
    viewer.trackedEntity = airplaneEntity;

    // Add onTick event listener for updating position and orientation of the airplane entity
    AddOnTickEventListenerForTwoD(viewer, airplaneEntity);

    // console.log(`airplaneEntity: `, airplaneEntity);

    return airplaneEntity;
}




/**
 * Calculates the distance between two points using their latitude, longitude, and altitude.
 * @param {number} DMELat - Latitude of the first point (DME).
 * @param {number} DMELong - Longitude of the first point (DME).
 * @param {number} DMEele - Altitude of the first point (DME) in meters.
 * @param {number} positionLat - Latitude of the second point.
 * @param {number} positionLong - Longitude of the second point.
 * @param {number} positionEle - Altitude of the second point in meters.
 * @returns {number} - The distance between the two points in nautical miles.
 */
function getDMEValue(DMELat, DMELong, DMEele, positionLat, positionLong, positionEle) {
    // Create Cartesian3 positions from degrees and altitude
    let pos1 = Cartesian3.fromDegrees(DMELat, DMELong, DMEele);
    let pos2 = Cartesian3.fromDegrees(positionLat, positionLong, positionEle);

    // Calculate the distance between the two positions
    let distance = Cartesian3.distance(pos1, pos2);

    // Convert the distance from meters to nautical miles
    return distance * METER_TO_NM;
}


/**
 * Calculates the final bearing angle between two geographical points.
 * @param {number} endLat - Latitude of the ending point.
 * @param {number} endLong - Longitude of the ending point.
 * @param {number} startLat - Latitude of the starting point.
 * @param {number} startLong - Longitude of the starting point.
 * @returns {number} - The final bearing angle in degrees.
 */
function getDMEAngle(endLat, endLong, startLat, startLong) {
    // Create LatLon instances for the starting and ending points
    let p1 = new LatLon(startLat, startLong);
    let p2 = new LatLon(endLat, endLong);

    // Calculate the final bearing angle from the starting point to the ending point
    return p1.finalBearingTo(p2);
}