import React, { useRef, useState, useLayoutEffect } from "react";
import fullScreenWindow from "../../../../../common/utils/fullScreenWindow";
import { getSegData } from "./DebriefPlayer";
import TwoAxisGraphTime from "../../graphs/TwoAxisGraphTime";
import TwoAxisGraphsWithRunway from "../../graphs/TwoAxisGraphsWithRunway";
import ThreeAxisGraphTime from "../../graphs/ThreeAxisGraphTime";
import ThreeAxisGraphsWithRunway from "../../graphs/ThreeAxisGraphsWithRunway";
import { parseDateTimeArray } from "./IasVsAltVsTimeContainer";
import { getLocalStorage } from "../../../../../common/utils/localStorageHandler";

const DraggableCard = (props: any) => {
    
    const idOne = useRef(null);
    
    // const eventHandler = (e: any, data: any) => {
    //     console.log('Event Type', e.type);
    //     console.log({ e, data });
    // }

    const pfdConatiner = useRef(null);
    const [flag, setFlag] = useState(false);

    const activeFullScreen = () => {
        fullScreenWindow('pdfLayerParent')
    }

    const [layoutSize, setLayoutSize] = useState({ width: 0, height: 0 });

    useLayoutEffect(() => {

    }, []);

    let segData = getSegData();

    let approachALTMSL: Number[] = [], landingALTMSL: Number[] = [], landingDistance: Number[] = [];
    let landingDistanceOverRunwayFt: Number[] = [], landingPitchOverRunway: Number[] = [], landingVspdOverRunway: Number[] = [];

    if (props.data.label === 'Approach') {
        approachALTMSL = props.data.data.map((item: any) => Number(item.AltMSL));
    }

    // if (props.data.label === 'Takeoff') {
    //     console.log(`TakeOff Data: `, props.data);
    // }
    
    if (props.data.label === 'Landing') {
        const landingPhaseALTMSL = props.data.data.map((item: any) => Number(item.AltMSL));
        const landingPhaseDistance = props.data.data.map((item: any) => Number(item.distFromRwy));

        // console.log(`landingPhaseDistance: `, landingPhaseDistance);

        const seqIdLanding = props.data.sequenceId;
        const seqIdApproach = seqIdLanding - 1;

        const approachPhaseData = segData.find((item: any) => item.sequenceId === (seqIdApproach));

        const approachPhaseALTMSL = approachPhaseData.data.map((item: any) => Number(item.AltMSL));
        const approachPhaseDistance = approachPhaseData.data.map((item: any) => Number(item.distFromRwy));

        // console.log(`approachPhaseDistance: `, approachPhaseDistance);

        // if (isNaN(approachPhaseDistance[approachPhaseDistance.length - 1])) {
        //     approachPhaseDistance[approachPhaseDistance.length - 1] = (approachPhaseDistance[approachPhaseDistance.length - 2] + landingPhaseDistance[0]) / 2
        // }
        
        
        // console.log(`approachPhaseDistance: `, approachPhaseDistance);
        // console.log(`landingPhaseDistance: `, landingPhaseDistance);

        landingALTMSL = approachPhaseALTMSL.concat(landingPhaseALTMSL);
        landingDistance = approachPhaseDistance.concat(landingPhaseDistance);
        
        landingDistance = landingDistance.filter((item:any) => !isNaN(item))

        // console.log(`landingDistance: `, landingDistance);

        let count = 0;
        const landingDistanceOverRunway = props.data.data.map((item: any) => {
            if (item.distFromRwy > 0) count++;
            return Math.abs(Math.min(0, Number(item.distFromRwy * 6076.12)))
        });
        landingDistanceOverRunwayFt = landingDistanceOverRunway.slice(count);
        landingPitchOverRunway = props.data.data.map((item: any) => Number(item.Pitch)).slice(count)
        landingVspdOverRunway = props.data.data.map((item: any) => Number(item.VSpd)).slice(count)
    }

    const newTab = (data: any) => {
        const encodedData = encodeURIComponent(JSON.stringify(data));
        const urlWithParameters = `/special-tabs/phaseGraph/${encodedData}`;
        window.open(urlWithParameters, '_blank', 'toolbar=no, location=no, status=no');
    };

    const authToken = getLocalStorage("authTokens", "");

    // if (props.data.label === "Landing" && props.graphName === "Alt vs Distance") {
    //     console.log(`xxxxxxxxxxxx`)
    //     console.log(`landingALTMSL: `, landingALTMSL);
    //     console.log(`landingDistance: `, landingDistance);
    //     console.log(`xxxxxxxxxxxx`)
    // }
    
    return (

        <div className={props.data.isVisible ? "debrief-cards" : "d-none"} id={props.id}>
            <div className="debrief-cards-handle">
                {props.data.label} : {props.graphName}
                <div className="button-holder d-flex">
                    {/* <button className="btn-customize"></button> */}
                    <button className="btn-maximize" onClick={() => fullScreenWindow(props.id)}></button>
                    <button className="btn-new-tab" onClick={() => newTab([props.data.sequenceId, props.graphName, props.data.label])}></button>
                </div>
            </div>
            <div className="debrief-cards-content">
    
                {/* TAXI PHASE */}

                {
                    props.data.label === "Taxi"
                    && props.graphName === "GndSpd vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        yaxisArray={props.data.data.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="Ground Speed (kn)" />
                }

                {/* TAKEOFF PHASE */}

                {
                    props.data.label === "Takeoff"
                    && props.graphName === "VSpd vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={props.data.data.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={props.data.data.map((item: any) => Number(item.VSpd))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="Vertical Speed (kn)" />
                }

                {
                    props.data.label === "Takeoff"
                    && props.graphName === "IAS vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={props.data.data.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="IAS (kn)" />
                }

                {
                    props.data.label === "Takeoff"
                    && props.graphName === "Alt vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={props.data.data.map((item:any) => Number(item.distFromRwy))}
                        yaxisArray={props.data.data.map((item:any) => Number(item.AltMSL))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="AltMSL (ft)"
                        phase="Takeoff" />
                }

                {/* CLIMB PHASE */}

                {
                    props.data.label === "Climb"
                    && props.graphName === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        yaxisArray={props.data.data.map((item: any) => Number(item.AltMSL))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    authToken.companyid !== 4 &&
                    props.data.label === "Climb"
                    && props.graphName === "IAS vs VSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        y1axisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        y2axisArray={props.data.data.map((item: any) => Number(item.VSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Vertical Speed (kn)" />
                }

                {/* CRUISE PHASE */}

                {
                    props.data.label === "Cruise"
                    && props.graphName === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        yaxisArray={props.data.data.map((item: any) => Number(item.AltMSL))}
                        phase="Cruise"
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    authToken.companyid !== 4 &&
                    props.data.label === "Cruise"
                    && props.graphName === "IAS vs GndSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        y1axisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        y2axisArray={props.data.data.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Ground Speed (kn)" />
                }

                {/* DESCENT PHASE */}

                {
                    props.data.label === "Descent"
                    && props.graphName === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        yaxisArray={props.data.data.map((item: any) => Number(item.AltMSL))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    props.data.label === "Descent"
                    && props.graphName === "VSpd vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        yaxisArray={props.data.data.map((item: any) => Number(item.VSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="Vertical Speed (kn)" />
                }

                {
                    authToken.companyid !== 4 &&
                    props.data.label === "Descent"
                    && props.graphName === "IAS vs GndSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        y1axisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        y2axisArray={props.data.data.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Ground Speed (kn)" />
                }

                {/* APPROACH PHASE */}

                {
                    props.data.label === "Approach"
                    && props.graphName === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        yaxisArray={approachALTMSL}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    authToken.companyid !== 4 &&
                    props.data.label === "Approach"
                    && props.graphName === "IAS vs GndSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        y1axisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        y2axisArray={props.data.data.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Ground Speed (kn)" />
                }

                {/* LANDING PHASE */}

                {
                    props.data.label === "Landing"
                    && props.graphName === "Alt vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={landingDistance}
                        yaxisArray={landingALTMSL}
                        xlabel="Distance from Runway (NM)"
                        ylabel="AltMSL (ft)"
                        phase="Landing" />
                }

                {
                    props.data.label === "Landing"
                    && props.graphName === "IAS vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={props.data.data.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="IAS (kn)"
                        phase="Landing" />
                }

                {
                    authToken.companyid !== 4 &&
                    props.data.label === "Landing"
                    && props.graphName === "VSpd vs Pitch vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        y1axisArray={props.data.data.map((item: any) => Number(item.VSpd))}
                        y2axisArray={props.data.data.map((item: any) => Number(item.Pitch))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="Vertical Speed (kn)"
                        y2label="Pitch (deg)" />
                }

                {
                    props.data.label === "Landing"
                    && props.graphName === "Pitch vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={props.data.data.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={props.data.data.map((item: any) => Number(item.Pitch))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="Pitch (deg)"
                        phase="Landing" />
                }

                {
                    authToken.companyid !== 4 &&
                    props.data.label === "Landing"
                    && props.graphName === "Alt vs IAS vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(props.data.data)}
                        y1axisArray={props.data.data.map((item: any) => Number(item.AltMSL))}
                        y2axisArray={props.data.data.map((item: any) => Number(item.IAS))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="AltMSL (ft)"
                        y2label="IAS (kn)" />
                }

                {
                    props.data.label === "Landing"
                    && props.graphName === "Pitch vs VSpd vs Distance"
                    && <ThreeAxisGraphsWithRunway xaxisArray={landingDistanceOverRunwayFt}
                    y1axisArray={landingPitchOverRunway}
                    y2axisArray={landingVspdOverRunway}
                        xlabel="Distance Over Runway (ft)"
                        y1label="Pitch (deg)"
                        y2label="Vertical Speed (kn)" />
                }

            </div>
        </div>
    )
}


const arePropsSame = (prevProps: any, nextProps: any) => {
    //If needed, add conditions to check if the specific properties you care about have changed
    //return true if you want to skip the update, and false if you want to re-render

    return (
        prevProps.renderAgain === nextProps.renderAgain
    );
};

// export default React.memo(DraggableCard);
export default React.memo(DraggableCard, arePropsSame);