import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./specialTabs.scss"
import { TabDataBrodacast, TabDataMsgType } from "../../../../common/utils/utils"
import { parseDateTimeArray } from "../debrief/debriefPlayer/IasVsAltVsTimeContainer";
import TwoAxisGraphTime from '../graphs/TwoAxisGraphTime';
import TwoAxisGraphsWithRunway from '../graphs/TwoAxisGraphsWithRunway';
import ThreeAxisGraphTime from '../graphs/ThreeAxisGraphTime';
import ThreeAxisGraphsWithRunway from '../graphs/ThreeAxisGraphsWithRunway';

const TabPhaseGraphs = () => {
    const { data } = useParams<{ data: string }>();
    const [segData, setSegData] = useState<any>();
    const [decodedData, setDecodedData] = useState<any>();

    TabDataBrodacast.onmessage = (msg) => {
        if (msg.id === TabDataMsgType.setPhaseGraphData) {
            setSegData(msg.data);
        }
        if (msg.id === TabDataMsgType.closeNewTabs) {
            window.close()
        }
        if (msg.id === TabDataMsgType.resetTab) {
            resetTab()
        }
    }

    useEffect(() => {
        // Ensure data is not undefined before parsing
        if (data) {
            setDecodedData(JSON.parse(decodeURIComponent(data)))
        }
    }, [data]);

    useEffect(() => {
        if (!segData && decodedData) {
            TabDataBrodacast.postMessage({ id: TabDataMsgType.getPhaseGraphData, data: decodedData[0] });
        }
    }, [segData, decodedData]);


    const resetTab = () => {
        setTimeout(() => {
            setSegData(null)
        }, 500)
    }

    // if (segData && decodedData[2] === "Landing" && decodedData[1] === "Alt vs Distance") {
    //     console.log(`xxxxxxxxx`);
    //     console.log(`segData.landingDistance: `, segData.landingDistance);
    //     console.log(`segData.landingALTMSL: `, segData.landingALTMSL);
    //     console.log(`xxxxxxxxx`);
    // }

    return (
        <div className="spl-tab">
            <div className='tab-parent' id="pfdLayerParent">

                {/* TAXI PHASE */}
                {
                    segData && decodedData[2] === "Taxi" && decodedData[1] === "GndSpd vs Time" &&
                    (<TwoAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        yaxisArray={segData.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="Ground Speed (kn)" />)
                }

                {/* TAKEOFF PHASE */}

                {
                    segData && decodedData[2] === "Takeoff" && decodedData[1] === "VSpd vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={segData.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={segData.map((item: any) => Number(item.VSpd))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="Vertical Speed (kn)" />
                }

                {
                    segData && decodedData[2] === "Takeoff" && decodedData[1] === "IAS vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={segData.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={segData.map((item: any) => Number(item.IAS))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="IAS (kn)" />
                }

                {
                    segData && decodedData[2] === "Takeoff" && decodedData[1] === "Alt vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={segData.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={segData.map((item: any) => Number(item.AltMSL))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="AltMSL (ft)"
                        phase="Takeoff" />
                }

                {/* CLIMB PHASE */}

                {
                    segData && decodedData[2] === "Climb" && decodedData[1] === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        yaxisArray={segData.map((item: any) => Number(item.AltMSL))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    segData && decodedData[2] === "Climb" && decodedData[1] === "IAS vs VSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        y1axisArray={segData.map((item: any) => Number(item.IAS))}
                        y2axisArray={segData.map((item: any) => Number(item.VSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Vertical Speed (kn)" />
                }

                {/* CRUISE PHASE */}

                {
                    segData && decodedData[2] === "Cruise" && decodedData[1] === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        yaxisArray={segData.map((item: any) => Number(item.AltMSL))}
                        phase="Cruise"
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    segData && decodedData[2] === "Cruise" && decodedData[1] === "IAS vs GndSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        y1axisArray={segData.map((item: any) => Number(item.IAS))}
                        y2axisArray={segData.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Ground Speed (kn)" />
                }

                {/* DESCENT PHASE */}

                {
                    segData && decodedData[2] === "Descent" && decodedData[1] === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        yaxisArray={segData.map((item: any) => Number(item.AltMSL))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    segData && decodedData[2] === "Descent" && decodedData[1] === "VSpd vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        yaxisArray={segData.map((item: any) => Number(item.VSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="Vertical Speed (kn)" />
                }

                {
                    segData && decodedData[2] === "Descent" && decodedData[1] === "IAS vs GndSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        y1axisArray={segData.map((item: any) => Number(item.IAS))}
                        y2axisArray={segData.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Ground Speed (kn)" />
                }

                {/* APPROACH PHASE */}

                {
                    segData && decodedData[2] === "Approach" && decodedData[1] === "Alt vs Time"
                    && <TwoAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        yaxisArray={segData.map((item: any) => Number(item.AltMSL))}
                        xlabel="Local Time (HH:MM:SS)"
                        ylabel="AltMSL (ft)" />
                }

                {
                    segData && decodedData[2] === "Approach" && decodedData[1] === "IAS vs GndSpd vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(segData)}
                        y1axisArray={segData.map((item: any) => Number(item.IAS))}
                        y2axisArray={segData.map((item: any) => Number(item.GndSpd))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="IAS (kn)"
                        y2label="Ground Speed (kn)" />
                }

                {/* LANDING PHASE */}

                {
                    segData && decodedData[2] === "Landing" && decodedData[1] === "Alt vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={segData.landingDistance.filter((item:any) => !isNaN(item))}
                        yaxisArray={segData.landingALTMSL.filter((item:any) => !isNaN(item))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="AltMSL (ft)"
                        phase="Landing" />
                }

                {
                    segData && decodedData[2] === "Landing" && decodedData[1] === "IAS vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.IAS))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="IAS (kn)"
                        phase="Landing" />
                }

                {
                    segData && decodedData[2] === "Landing" && decodedData[1] === "VSpd vs Pitch vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(segData.landingPhaseOnlyData)}
                        y1axisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.VSpd))}
                        y2axisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.Pitch))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="Vertical Speed (kn)"
                        y2label="Pitch (deg)" />
                }

                {
                    segData && decodedData[2] === "Landing" && decodedData[1] === "Pitch vs Distance"
                    && <TwoAxisGraphsWithRunway xaxisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.distFromRwy))}
                        yaxisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.Pitch))}
                        xlabel="Distance from Runway (NM)"
                        ylabel="Pitch (deg)"
                        phase="Landing" />
                }

                {
                    segData && decodedData[2] === "Landing" && decodedData[1] === "Alt vs IAS vs Time"
                    && <ThreeAxisGraphTime xaxisArray={parseDateTimeArray(segData.landingPhaseOnlyData)}
                        y1axisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.AltMSL))}
                        y2axisArray={segData.landingPhaseOnlyData.map((item: any) => Number(item.IAS))}
                        xlabel="Local Time (HH:MM:SS)"
                        y1label="AltMSL (ft)"
                        y2label="IAS (kn)" />
                }

                {
                    segData && decodedData[2] === "Landing" && decodedData[1] === "Pitch vs VSpd vs Distance"
                    && <ThreeAxisGraphsWithRunway xaxisArray={segData.landingDistanceOverRunwayFt}
                        y1axisArray={segData.landingPitchOverRunway}
                        y2axisArray={segData.landingVspdOverRunway}
                        xlabel="Distance Over Runway (ft)"
                        y1label="Pitch (deg)"
                        y2label="Vertical Speed (kn)" />
                }

            </div>
        </div>
    );
};

export default TabPhaseGraphs;