import { Ion, SceneMode, Viewer, createWorldTerrainAsync } from "cesium";
import { clockViewModel } from "./cesiumDataHandler";

Ion.defaultAccessToken = process.env.REACT_APP_CESIUMTOKEN;

/**
 * Initializes and configures the Cesium viewer with terrain and various options.
 * @returns {void}
 */
export async function InitViewer() {
    try {
        // Create a World Terrain Provider asynchronously
        const terrainProvider = await createWorldTerrainAsync();

        // Initialize the 3D viewer (Viewer1)
        const Viewer1 = new Viewer('viewer3d', {
            terrainProvider: terrainProvider,
            clockViewModel: clockViewModel,
            sceneMode: SceneMode.SCENE3D,
            baseLayerPicker: false,
            vrButton: false,
            homeButton: false,
            sceneModePicker: false,
            projectionPicker: false,
            navigationHelpButton: false,
            animation: false,
            fullscreenButton: false,
            infoBox: false,
            geocoder: false,
            selectionIndicator: false,
        });

        // Initialize the second viewer (Viewer2)
        const Viewer2 = new Viewer('viewer', {
            terrainProvider: terrainProvider
        });
    } catch (error) {
        console.log("Viewer not loaded " + error);
    }
}


/**
 * Loads and returns a World Terrain Provider asynchronously.
 * @returns {TerrainProvider} - The loaded terrain provider.
 */
export async function LoadTerrain() {
    try {
        const terrainProvider = await createWorldTerrainAsync();
        return terrainProvider;
    } catch (error) {
        console.log("Terrain not loaded " + error);
    }
}


/**
 * Initializes and configures the first Cesium viewer with specified terrain and options.
 * @param {TerrainProvider} terrainProvider - The terrain provider for the viewer.
 * @returns {Viewer} - The initialized Cesium viewer.
 */
export function InitViewer1(terrainProvider) {
    // Create the first viewer (Viewer1) with specified options
    //viewer3d is id of an element 
    const Viewer1 = new Viewer('viewer3d', {
        terrainProvider: terrainProvider,
        clockViewModel: clockViewModel,
        sceneMode: SceneMode.SCENE3D,
        baseLayerPicker: false,
        vrButton: false,
        homeButton: false,
        sceneModePicker: false,
        projectionPicker: false,
        navigationHelpButton: false,
        animation: false,
        fullscreenButton: false,
        infoBox: false,
        geocoder: false,
        selectionIndicator: false,
    });

    return Viewer1;
}


/**
 * Initializes and configures the second Cesium viewer with specified terrain and options.
 * @param {TerrainProvider} terrainProvider - The terrain provider for the cesium.
 * @returns {Viewer} - The initialized Cesium viewer.
 */
export function InitViewer2(terrainProvider) {
    // Create the second viewer (Viewer1) with specified options
    //viewer is id of an html element 
    const Viewer2 = new Viewer('viewer', {
        terrainProvider: terrainProvider,
        clockViewModel: clockViewModel,
        sceneMode: SceneMode.SCENE3D,
        baseLayerPicker: false,
        vrButton: false,
        homeButton: false,
        sceneModePicker: false,
        projectionPicker: false,
        navigationHelpButton: false,
        animation: false,
        fullscreenButton: false,
        infoBox: false,
        geocoder: false,
        selectionIndicator: false,
    });
    return Viewer2;
}
